<template>
  <div>
    <DataTable
      :canSelect="canUpload"
      :cols="cols"
      :data="items"
      selectable
      ref="drafts"
      @select="select"
      @selectOne="selectOne"
    >
      <template v-slot:item.actions="{ item }">
        <ActionButton
          v-if="canUpload(item)"
          color="success"
          icon="mdi-upload"
          tooltip="Submit Finding"
          @click="$emit('uploadOne', item)"
        />
        <!-- <ActionButton
          v-else
          disabled
          icon="mdi-upload"
          tooltip="This Finding is not complete"
        /> -->
        <ActionButton
          color="red"
          icon="mdi-close"
          tooltip="Discard Draft"
          @click="$emit('remove', item)"
        />
      </template>
    </DataTable>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import ActionButton from '@/components/UI/data-table/line-item/action-button'

export default {
  components: {
    ActionButton
  },
  props: {
    items: {
      type: Array,
      default() { return [] }
    }
  },
  data () {
    return {
      cols: [
        {
          field: 'acbDate',
          label: 'Date',
          width: '10%',
          valueFunction: (item, field) => {
            return new Date(item[field]).toIACSformat()

          }
        },
        {
          field: 'acbFinding_Type',
          label: 'Type',
          width: '10%'
        },
        {
          field: 'acbAudit',
          label: 'Audit Ref.',
          width: '10%'
        },
        {
          field: 'acbObs',
          label: 'Finding No.',
          width: '7%'
        },
        {
          field: 'acbClass',
          label: 'Class',
          width: '5%'
        },
        {
          field: 'acbAuditType',
          label: 'Audit Type',
          width: '15%',
          truncate: true
        },
        {
          field: 'acbLocation',
          label: 'Location',
          width: '15%',
          truncate: true
        },
        {
          field: 'acbFindingFile',
          label: 'File Name',
          width: '28%',
          truncate: true
        },
        {
          align: 'right',
          compact: true,
          field: 'actions',
          label: '',
          width: '10%'
        }
      ],
      selected: []
    }
  },
  computed: {
    ...mapGetters('DraftFinding', ['canUpload'])
  },
  methods: {
    selectOne(selection) {
      this.$emit('selectOne', selection)
    },
    select(selected) {
      this.selected = selected
      this.$emit('select', this.selected)
    }
  }
}
</script>
