<template>
  <v-row
    align="center"
    dense
    no-gutters
  >
    <Confirm ref="confirm" />

    <PageSubtitle
      :icon="false"
      title="Upload History"
    />

    <v-col cols="12" class="mb-6">
      <List
        v-if="uploads.length"
        :items="uploads"
        @view="view"
      />
      <Message v-else />
    </v-col>

    <DocumentDrawer
      hide-print
      :open="documentDrawerOpen"
      :stateless="stateless"
      :title="'Finding Report'"
      :width="960"
      @close="documentDrawerOpen=false"
    >
      <template v-slot:actionMenu>
        <ActionMenu
          :items="actionMenuItems"
        />
      </template>
      <template
        v-slot:default
      >
        <SingleViewWrapper
          v-if="selected"
          :finding="selected"
        />
      </template>
    </DocumentDrawer>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex'

import ActionMenu from '@/components/UI/action-menu'
import DocumentDrawer from '@/components/UI/document-drawer'
import PageSubtitle from '@/components/UI/page-subtitle'

import List from './list'
import Message from './message'
import SingleViewWrapper from './single-view-wrapper'

export default {
  components: {
    ActionMenu,
    DocumentDrawer,
    List,
    Message,
    PageSubtitle,
    SingleViewWrapper
  },
  data () {
    return {
      documentDrawerOpen: false,
      actionMenuItems: [
        {
          label: 'Print',
          icon: 'mdi-close',
          action: this.print
        }
      ],
      selected: null,
      stateless: false
    }
  },
  computed: {
    ...mapGetters({ uploads: 'Uploads/uploaded' })
  },
  methods: {
    async create () {
      this.createNew()
      this.documentDrawerOpen=true
    },
    select(selection) {
      this.setFinding(selection)
      this.documentDrawerOpen=true
    },
    print() {},
    view({ item, index }) {
      this.selected = item.uploads[index]
      this.documentDrawerOpen = true
    }
  }
}
</script>
