<template>
  <v-row v-if="!isOnline" dense no-gutters>
    <!-- <v-col cols="12" class="">
      <v-alert
        class="mb-0"
        dense
        type="error"
      >
        <b>Not connected to the ARM system, findings can not be uploaded.</b>
      </v-alert>
    </v-col> -->
  </v-row>
</template>

<style scoped>
  .v-alert__content{
    display: inline-block;
  }
</style>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({ queued: 'Uploads/queued' }),
    isOnline () {
      return this.$store.state.socket
    },
    count () {
      return this.queued.reduce((accumulator, q) => accumulator + q.uploads.length, 0)
    }
  },
  methods: {
    processQ () {
      // console.log(`online! process ${this.queued.length} uploads.`)
      this.queued.forEach(q => {
        this.$store.dispatch('Uploads/upload', q).then(() => {
          // notify
        })
      })
    }
  },
  watch: {
    queued (newVal, oldVal) {
      // if online and queue length has increased
      if (this.isOnline && newVal.length > oldVal.length){
        this.processQ()
      }
    },
    isOnline (newVal, oldVal) {
      // rising edge trigger (false > true)
      if (!oldVal && newVal) {
        this.processQ()
      }
    }
  }
}
</script>
