<template>
  <v-sheet
    v-if="!finding.acbCloseDate"
    class="px-4 py-4 mr-5 mt-4"
    color="amber lighten-4"
    style="margin-left:140px"
    width="100%"
  >
    <Confirm ref="confirm" />

    <div class="text-h5 pb-2 px-1 brown--text text--darken-2">{{ dictionary['acbNotes'].longname }}</div>

    <v-layout column>
      <TextArea
        auto-grow
        class="mb-2"
        field="acbNotes"
        height="auto"
        :value="text"
        :placeholder="`Enter ${dictionary['acbNotes'].longname} here ...`"
        @change="change"
      />
      <v-layout align-center justify-space-between>
        <div class="">
          Closed Date: {{ new Date().toIACSformat() }}
        </div>
        <div>
          <v-btn
            depressed
            color="primary"
            :loading="saving"
            :disabled="!(text)"
            @click="update"
          >
            Update Notes
          </v-btn>
          <v-btn
            v-if="finding.acbCompDate && canCloseFindings"
            class="ml-2"
            color="primary"
            depressed
            :loading="closing"
            :disabled="!(date)"
            @click="updateAndClose"
          >
            Close Finding
          </v-btn>
        </div>
      </v-layout>
    </v-layout>
  </v-sheet>
</template>

<script>
import { dictionary } from '@/models/finding'

import TextArea from '@/components/UI/forms/textarea'

export default {
  components: {
    TextArea
  },
  props: {
    finding: {
      type: Object,
      default() { return {} }
    },
    close: {
      type: Function
    }
  },
  data() {
    return {
      date: new Date().toISOString().substr(0, 10),
      dictionary,
      closing: false,
      saving: false,
      text: null
    }
  },
  computed: {
    canCloseFindings() {
      // console.log(this.$store.state)
      return this.$store.state.auth.user.canCloseFindings
    }
  },
  methods: {
    change({ field, value}) {
      if (field === 'acbNotes'){
        this.text = value
      }
      if (field === 'acbCloseDate'){
        this.date = value
      }
      // console.log(field, value)
    },
    update() {
      this.saving = true
      const upd = {
        acbNotes: this.text
      }
      this.finding.patch({ data: upd }).then(() => {
        this.saving = false
      })
    },
    async updateAndClose() {

      // reference to the confirm dialog
      const confirm = this.$refs.confirm

      this.closing = true

      const result = await confirm.open(
        'Close Finding',
        `Are you sure you want to close ${this.finding.acbFindingFile} ?`,
        {
          acceptColor: 'primary',
          acceptLabel: 'Close Finding',
          cancelLabel: 'Cancel',
          color: 'primary',
          icon: 'mdi-help-circle',
          width: 410
        }
      )

      if (result) {
        const upd = {
          acbClosedBy: this.$store.state.auth.user.name,
          acbCloseDate: this.date,
          acbNotes: this.text
        }
        await this.finding.patch({ data: upd }).then(() => {
          setTimeout(() => {
            this.closing = false
            this.$snotify.success('Finding Closed', 'Success!')
            this.close()
          }, 300)
        }).catch(() => {
        })
      }
      this.closing = false
    }
  },
  watch: {
    finding: {
      handler (newVal){
        this.text = newVal.acbNotes
        this.date = (newVal.acbCloseDate
         ? new Date(newVal.acbCloseDate)
         : new Date()).toISOString().substr(0, 10)
      },
      immediate: true,
      deep: true
    }
  }
}
</script>
