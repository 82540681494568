<template>
  <v-tooltip bottom :transition="'none'">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="line-item-action-button px-0"
        tile
        text
        small
        :color="color"
        :disabled="disabled"
        @click.stop="$emit('click')"
      ><v-icon>{{ icon }}</v-icon></v-btn>
    </template>
    <span>{{ tooltip }}</span>
  </v-tooltip>
</template>

<style scoped>
.line-item-action-button.v-btn.v-size--small {
  width: 32px;
  min-width: 32px !important;
  max-width: 32px !important;
  height: 32px;
  min-height: 32px !important;
  max-height: 32px !important;
  /*color: rgba(0,0,0,0.35) !important;*/
}
.line-item-action-button.v-btn.v-size--small:hover {
  /*color: rgba(0,136,204,1) !important;*/
}
.line-item-action-button.v-btn.v-size--small::before{
  background-color: transparent;
}
</style>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: 'mdi-delete'
    },
    tooltip: {
      type: String,
      default: 'Delete'
    },
  }
}
</script>
