<template>
  <v-container
    fluid
  >
    <v-row
      class="mx-3"
      dense
      no-gutters
    >
      <v-col cols="12" class="mt-2 px-0">
        <v-alert
          v-if="!canUpload(report)"
          type="error"
        >
          You can't submit a finding until all the required fields are completed.
          <br />
          Required fields are marked in red.
        </v-alert>
        <v-alert
          v-else
          type="success"
        >
          This finding can be submitted.
        </v-alert>
      </v-col>
      <v-col cols="12" class="d-flex justify-space-between mt-2 mb-4 px-0">
        <div class="d-flex pl-5">
          <div class="field-label text-caption mt-1">
            <span class="font-weight-bold primary--text">
              Finding Type
            </span>
          </div>
          <RadioGroup
            field="acbFinding_Type"
            :column="false"
            :items="findingTypes"
            :value="report.acbFinding_Type"
            @change="change"
          />
        </div>
      </v-col>
      <v-col cols="12" class="mx-n1">
        <Tabs>
          <Tab label="Finding Details">
            <FindingDetails :report="report"/>
          </Tab>
          <Tab label="Finding Text" :color="report.acbFinding_Text ? '' : 'error'">
            <FindingText :report="report"/>
          </Tab>
          <!-- <Tab label="__ DEVELOPER VIEW __.">
            <Raw :report="report" />
          </Tab> -->
        </Tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
  .field-label {
    max-width: 130px;
    min-width: 130px;
    width: 130px;
  }
  .field-label, .field-value {
    padding: 0.25rem 0.125rem;
  }
  .underline {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgba(0, 0, 0, 0.12);
  }
</style>

<script>
import { mapGetters } from 'vuex'
import { computed } from '@vue/composition-api'
import { useFind } from 'feathers-vuex'

import RadioGroup from '@/components/UI/forms/radio-group'

import FindingDetails from './tabs/details'
import FindingText from './tabs/text'
// import Raw from './tabs/raw'

export default {
  components: {
    FindingDetails,
    FindingText,
    RadioGroup,
    // Raw
  },
  props: {
    report: {
      type: Object,
      default () { return {} }
    }
  },
  computed: {
    ...mapGetters('DraftFinding', ['canSave', 'canUpload', 'filename']),
    correctiveActions () {
      return null
    },
  },
  methods: {
    change ({ field, value }) {
      const report = ({ ...this.report, [field]:value })
      this.$store.dispatch('DraftFinding/update', report)
      if (this.$store.getters['DraftFinding/canSave']){
        this.$store.dispatch('LocalFindings/saveFinding', report)
      }
    },
  },
  watch: { report () {} },
  setup (props, context) {
    const { ValueList } = context.root.$FeathersVuex.api

    const { items } = useFind({
      model: ValueList,
      params: {query: {}}
    })

    const findingTypes = computed(() => {
      const list = items.value.find(l => l.field === 'acbFinding_Type')
      if(!list) return
      return list.values
    })

    return {
      findingTypes
    }
  }
}
</script>
