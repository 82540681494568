<template>
  <v-navigation-drawer
    _absolute
    class="menu ml-1"
    expand-on-hover
    mini-variant
    mini-variant-width="72"
    permanent
    width="240"
  >
    <v-list
      nav
      _dense
    >
      <v-list-item-group
        v-model="index"
        color="primary"
        mandatory
        no-action
      >
        <v-list-item
          v-for="(item, i) in menuItems"
          class="mb-1"
          :key="i"
          :ripple="false"
        >
          <v-list-item-icon class="mx-2">
            <v-badge
              left
              bordered
              offset-x="36"
              offset-y="8"
              class="font-weight-black"
              :color="item.countColor || 'red'"
              :content="item.count()"
              :value="item.count()"
            >
              <v-icon>{{ item.icon }}</v-icon>
            </v-badge>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="pl-4">{{ item.label }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<style scoped>
.menu{
  position: relative;
  overflow: visible !important;
  /*z-index: 1;*/
  transform-style: preserve-3d;
  will-change: all;
}
.menu:hover::after{
  content:' ';
  position: absolute;
  /*z-index: -1;*/
  width: 10px;
  height: calc(100% - 20px);
  top:10px;
  right:4px;
  transform: translateZ(-1px);
  box-shadow: 5px 0px 12px 0px rgba(0,0,0,0.125);
  will-change: all;
}
.v-list-item{
  justify-content: flex-start !important;
}
</style>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {

    }
  },
  mounted () {
    this.$store.dispatch('rejected/fetchTotals')
  },
  computed: {
    ...mapGetters('LocalFindings', ['findings']),
    ...mapGetters('Uploads', ['queued', 'uploaded']),
    ...mapGetters('rejected', [ 'count' ] ),
    index: {
      get: function(){
        return this.value
      },
      set: function(value){
        this.$emit('select', value)
      }
    },
    menuItems () {
      const items = [{
          icon: 'mdi-book-outline',
          label: 'Draft Findings',
          count: () => (this.findings||[]).length,
          countColor: 'light-green darken-1'
        },
        {
          icon: 'mdi-file-remove-outline',
          label: 'Rejected Findings',
          count: () => this.count || 0
          // component: Submitted
        },
        {
          icon: 'mdi-history',
          label: 'Upload History',
          count: () => (this.uploaded||[]).length,
          countColor: 'primary'
          // component: Queue
        },
      ]
      if (!this.$store.state.socket) {
        items.push({
          icon: 'mdi-tray-full',
          label: 'Upload Queue',
          count: () => (this.queued||[]).length,
          countColor: 'orange darken-1'
          // component: Queue
        })
      }
      return items
    }
  }
}
</script>
