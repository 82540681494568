<template>
  <v-row
    class=""
    dense
    no-gutters
  >
    <v-col
      v-for="n in 2"
      cols="6"
      class="px-6"
      :key="n"
    >
      <template
        v-for="(field, i) in fields"
      >
        <v-row
          v-if="field.column === n"
          class="mt-1"
          dense
          no-gutters
          :key="i"
        >
          <FormGroup
            :itemsFrom="items"
            :field="field"
            :required="required(field)"
            :disabled="disabled(field)"
            :value="report[field.field]"
            @change="change"
          />
        </v-row>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useFind } from 'feathers-vuex'
import { mapGetters } from 'vuex'

import FormGroup from './form-group'

import { fields } from '@/store/modules/draft-finding/field-definitions'

export default {
  components: {
    FormGroup
  },
  props: {
    report: {
      type: Object,
      default() { return {} }
    }
  },
  computed: {
    ...mapGetters('DraftFinding', {
      disabled: 'fieldDisabled',
      required: 'fieldRequired'
    })
  },
  setup (props, context) {
    const { $store } = context.root
    const { ACB, Auditor, ClassSociety, FlagState, ValueList } = context.root.$FeathersVuex.api

    const { items: acbs  } = useFind({
      model: ACB,
      params: {query: {$limit:500}}
    })

    const { items: auditors  } = useFind({
      model: Auditor,
      params: {query: {$limit:500}}
    })

    const { items: classSocieties  } = useFind({
      model: ClassSociety,
      params: {query: {$limit:500}}
    })

    const { items: flagStates  } = useFind({
      model: FlagState,
      params: {query: {$limit:500}}
    })

    const { items: valueLists  } = useFind({
      model: ValueList,
      params: {query: {}}
    })

    // getters

    const items = computed(() => {
      return field => {
        if (field=="acbACB") {
          return acbs.value.map(acb => acb.value)
        }
        if (field=="acbAuditor") {
          return auditors.value.map(ac => ac.value)
        }
        if (field=="acbClass") {
          return classSocieties.value.map(cs => cs.value)
        }
        if (field=="acbFlag") {
          return flagStates.value.map(fs => fs.name)
        }
        const item = valueLists.value.find(l => l.field === field)
        if(!item) return
        if (field=="acbIQMSR") {
          return item.values.map(v => v.value)
        }
        return item.values
      }
    })

    const getCSACB = value => {
      const cs = classSocieties.value.find(cs => cs.value === value)
      const acb = acbs.value.find(acb => acb._id === cs.acb._id)
      return acb.value
    }

    // implementation of field dependencies

    const change = ({ field, value }) => {
      let report = ({ ...props.report, [field]: value })

      // clear acbVCAType if not correct audit type
      if(field === 'acbAuditType'){
        const isVCA =  value === 'VCA'
        const acbVCAType = isVCA ? props.report.acbVCAType : null
        report = ({ ...report, acbVCAType: acbVCAType })
      }

      // find the correct ACB for selected Class
      if(field === 'acbClass'){
        report = ({ ...report, acbACB: getCSACB(value) })
      }

      // if system, clear technical
      if(field === 'acbSystem'){
        report = ({ ...report, acbTechnical: null })
      }

      // if technical, clear system
      if(field === 'acbTechnical'){
        report = ({ ...report, acbSystem: null })
      }

      // if QMSR, set RO code
      if(field === 'acbIQMSR'){
        const iqmsrs = valueLists.value.find(l => l.field === 'acbIQMSR').values
        const iqmsr = iqmsrs.find(i => i.value === value) || {}
        const ro = iqmsr.ro
        report = ({ ...report, acbRO_Code: ro })
      }

      $store.dispatch('DraftFinding/update', report).then(() => {
        if ($store.getters['DraftFinding/canSave']){
          $store.dispatch('LocalFindings/saveFinding', report)
        }
      })
    }

    return {
      auditors,
      change,
      fields,
      items
    }
  }
}
</script>
